@font-face {
  font-family: 'pixelFont';
  src: url(./Nintendo-DS-BIOS.ttf);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: 'pixelFont';
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
  color: rgb(39, 39, 39)
}

.project-panels {
  background-image: url(./Images/ELY.png);
}


.modal-img {
  object-fit: contain;
  height: 90dvh;
  background-color: transparent;
}

.modal-body {
  border-color: white;
}

.modal {
  --bs-modal-border-color: none !important
}

.navbar-toggler {
  border: transparent;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
}

.box-header {
  color: rgb(39, 39, 39);
  background-color: rgb(197, 197, 197);
}

.box-body {
  border-color: none !important;

}

#hamburger {
  text-align: right;
  cursor: pointer;
}


@media (min-width: 768px) {

  #about-container {
    width: 50%;

  }
}